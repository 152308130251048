import { createSlice } from '@reduxjs/toolkit'


const initialState = { 
  token: "loading",
  companyName: "",

  currentStage: "landing-page", // landing-page, step-one, step-two, step-three, thank-you
  subStageNum: 0, // 0 to 6 with 0.5
  userFurthestSubStageNum: 0,

  company: {
    name: "ABC Company"
  },


  form: {},
}

const onboardingSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload
    },
    setCompanyName(state, action) {
      state.companyName = action.payload
    },
    setCurrentStage(state, action) {
      state.currentStage = action.payload
    },
    setSubStageNum(state, action) {
      state.subStageNum = action.payload
      if (state.userFurthestSubStageNum < action.payload) {
        state.userFurthestSubStageNum = action.payload
      }
    },
    setFormWithOverride(state, action) {
      state.form = {
        ...state.form,
        ...action.payload
      }
    }
  },
});

const allActions = onboardingSlice.actions

export const onboardingActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
  
}

export default onboardingSlice.reducer;