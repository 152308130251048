import dayjs from "dayjs"
import React from "react"


// ======================> ETC <======================

// if navigator.clipboard not available
/**
 * The above function is a fallback method for copying text to the clipboard in JavaScript.
 * @param text - The `text` parameter is the text that you want to copy to the clipboard.
 */

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

/**
 * The `formatShortDate` function takes a start and end date as input and returns a formatted short
 * date and time range.
 * @param start - The `start` parameter is the start date and time of an event. It is expected to be in
 * a valid date and time format that can be parsed by the `dayjs` library.
 * @param end - The `end` parameter is the end date and time of an event. It is optional and can be
 * left empty.
 * @returns The function `formatShortDate` returns an object with two properties: `date` and `time`.
 */

export const formatShortDate = (start, end) => {
  if (!start) return {}

  let date = ""
  let time = ""

  const startJs = dayjs(start)
  const endJs = end && dayjs(end)

  const isStartAndEndSameDate = startJs.isSame(endJs, "date")
  
  if (!end || isStartAndEndSameDate) {
    date = startJs.format("DD MMM")

    time += startJs.format("hh:mma")
    if (!!end) {
      time += "-"
      time += endJs.format("hh:mma")
    }

  } else {
    const isStartAndEndSameMonth = startJs.isSame(endJs, "month")
    let startString = ""
    let endString = ""
  
    if (isStartAndEndSameMonth) {
      startString = startJs.format("DD")
      endString = endJs.format("DD MMM")
  
    } else {
      startString = startJs.format("DD MMM")
      endString = endJs.format("DD MMM")
    }

    date = startString + " - " + endString

    startString = startJs.format("hh:mma")
    endString = endJs.format("hh:mma")

    time = startString + " - " + endString
  }

  return {
    date, time
  }
}

/**
 * The function `getNameFirstTwoInitial` takes a name as input and returns the first two initials of
 * the name in uppercase.
 * @param name - The `name` parameter is a string representing a person's full name.
 * @returns The function `getNameFirstTwoInitial` returns a string that consists of the first two
 * initials of a given name.
 */

export const getNameFirstTwoInitial = (name) => {
  if (!name) return "";
  
  const splitted = name.trim().split(/\s+/); // Split by any whitespace and remove extra spaces
  
  if (splitted.length === 1) {
    return splitted[0][0] ? splitted[0][0].toUpperCase() : ""; // Handle case where name[0] might not exist
  } else {
    const firstInitial = splitted[0][0] ? splitted[0][0].toUpperCase() : "";
    const secondInitial = splitted[1][0] ? splitted[1][0].toUpperCase() : "";
    return firstInitial + secondInitial;
  }
};

/**
 * The function `isTextContains` checks if a string `a` contains another string `b`, regardless of case
 * sensitivity.
 * @param a - The parameter `a` represents the text that you want to search within.
 * @param b - The parameter `b` is a string that represents the text you want to search for within
 * another text.
 * @returns a boolean value indicating whether the text in variable `a` contains the text in variable
 * `b`.
 */

export const isTextContains = (textA, textB) => {
  if (!textA || !textB) {
    throw new Error('Both textA and textB must be provided.');
  }

  const upperTextA = textA.toUpperCase();
  const upperTextB = textB.toUpperCase();

  return upperTextA.includes(upperTextB);
};

/**
 * The function `isValidEmail` checks if a given value is a valid email address.
 * @param val - The `val` parameter represents the value that needs to be checked for email validity.
 * @returns The regular expression match result is being returned.
 */

export const isValidEmail = (val) => {
  if (!val) return

  return String(val)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

/**
 * The `formatSize` function takes a size in bytes and returns a formatted string with the size
 * converted to the appropriate unit (B, KB, MB, GB, or TB) with two decimal places.
 * @param size - The `size` parameter represents the size in bytes that you want to format.
 * @returns The function `formatSize` returns a formatted string representing the size. The size is
 * converted to the appropriate unit (B, KB, MB, GB, or TB) based on its value. The returned string
 * includes the minimized size with two decimal places and the corresponding unit.
 */

export const formatSize = (size) => {
  if (typeof size !== 'number' || isNaN(size) || size < 0) {
    throw new Error('Invalid size. Size must be a non-negative number.');
  }

  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let formattedSize = size;
  let unitIndex = 0;

  while (formattedSize >= 1000 && unitIndex < units.length - 1) {
    formattedSize /= 1000;
    unitIndex++;
  }

  return formattedSize.toFixed(2) + ' ' + units[unitIndex];
}

/*  Get object variable by string */
/**
 * The function `fetchFromObject` retrieves a nested property from an object using dot notation.
 * @param obj - The `obj` parameter is the object from which you want to fetch a property value. It can
 * be any valid JavaScript object.
 * @param prop - The `prop` parameter is a string that represents the property you want to fetch from
 * the `obj` object. It can be a nested property, separated by dots. For example, if `prop` is
 * "person.name", it means you want to fetch the value of the "name" property
 * @returns The function `fetchFromObject` returns the value of the specified property (`prop`) from
 * the given object (`obj`). If the property is not found, it returns `false`.
 */

export const fetchFromObject = (obj, prop) => {
  // If the object is undefined or null, return undefined
  if (obj === undefined || obj === null) return undefined;
  
  // Index of the next property split
  const index = prop.indexOf('.');

  // Property split found; recursive call
  if (index > -1) {
    // Get object at property (before split), pass on remainder
    return fetchFromObject(obj[prop.substring(0, index)], prop.substr(index + 1));
  }
  
  // No split; get property
  return obj[prop];
}

/**
 * The `getRange` function returns an array of numbers from `start` to `end` (excluding `end`).
 * @param start - The `start` parameter is the starting value of the range.
 * @param end - The `end` parameter represents the end value of the range.
 * @returns The function `getRange` returns an array containing all the numbers from `start`
 * (inclusive) to `end` (exclusive).
 */

export const getRange = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

/**
 * The function `getOuterRange` returns an array of numbers that are outside the range specified by the
 * `start` and `end` parameters, within the range of 0 to `max`.
 * @param start - The `start` parameter represents the starting index of the outer range. It is a
 * number that indicates the first index that should be excluded from the result array.
 * @param end - The `end` parameter represents the end value of the range.
 * @param max - The `max` parameter represents the maximum value for the range. It is used to determine
 * the upper limit of the range.
 * @returns The function `getOuterRange` returns an array containing all the numbers that are less than
 * the `start` parameter or greater than the `end` parameter, within the range of 0 to `max`.
 */

export const getOuterRange = (start, end, max) => {
  const result = [];
  for (let i = 0; i < max; i++) {
    if (i < Number(start) || i > Number(end)) {
      result.push(i);
    }
  }
  return result;
}

/**
 * The function checks if a given component is a valid React component.
 * @param component - The `component` parameter is the object that we want to check if it is a valid
 * React component.
 * @returns a boolean value.
 */

export const isReactComponent = (component) => {
  return (
    !!component
    && typeof component === 'object' 
    && React.isValidElement(component)
  )
}

/**
 * The function `isValidURL` checks if a given string is a valid URL.
 * @param url - The `url` parameter is a string representing a URL that needs to be validated.
 * @returns The function `isValidURL` returns a boolean value. It returns `true` if the given `url` is
 * a valid URL, and `false` otherwise.
 */

export const isValidURL = (url) => {
  try { 
    return Boolean(new URL(url))
  }
  catch(e) { 
    return false
  }
}

/**
 * The function `roundOffHourAndHalf` rounds off a given value in seconds to the nearest hour or half
 * hour.
 * @param value - The `value` parameter represents the number of seconds that you want to convert to
 * hours and round off.
 * @returns either a rounded off hour value or a rounded off hour and half value, depending on the
 * input.
 */

export const roundOffHourAndHalf = (value) => {
  const inSecond = value || 0
  const inHour = inSecond / 60 / 60
  const inHourStringList = String(inHour).split(".")
  const inHourFirstDecimal = inHourStringList[1]

  if (!!inHourFirstDecimal && inHourFirstDecimal[0] === "5") {
    return Number(inHourStringList[0] + "." + 5)
  }

  return inHour.toFixed(0)
}

/**
 * The function checks if a given text is a valid base64 encoded string.
 * @param text - The `text` parameter is a string that represents the text that you want to check if it
 * is a valid base64 encoded string.
 * @returns a boolean value indicating whether the given text is a valid base64 encoded string or not.
 */

export const isBase64 = (text) => { 
  return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(text)
}

/**
 * This function formats a decimal value to a specified number of decimal points.
 * @param {number} value - The `value` parameter represents the decimal value you want to format.
 * @param {number} decimalPoint - The `decimalPoint` parameter specifies the number of decimal points 
 * you want to retain in the formatted value.
 * @returns {number} The formatted decimal value with the specified number of decimal points.
 */

export const formatDecimalPoint = (value, decimalPoint) => {
  return Math.round(value * (Math.pow(10, decimalPoint))) / Math.pow(10, decimalPoint)
}

/**
 * The function `formatHourAndMinutes` formats a given duration in seconds into hours and minutes.
 * @param { number } value - The `value` parameter represents the duration in seconds to convert.
 * @param { Function } t - The `t` parameter represents a translation function for text localization.
 * @returns { string } the formatted time in hours and minutes.
 */

export const formatHourAndMinutes = (value, t) => {
  const totalSeconds = parseInt(value, 10);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const hourText = hours > 0 ? `${hours} ${t("general.hour_abbrev")}` : "";
  const minuteText = minutes > 0 ? `${minutes} ${t("general.minutes_abbrev")}` : "";

  return `${hourText} ${minuteText}`;
}

/**
 * This function formats the label for a timezone.
 * @param {object} timezone - The `timezone` parameter represents the timezone object containing information 
 * such as name and code.
 * @returns {string} The formatted timezone label, including the timezone name and code.
 */

export const formatTimezoneLabel = (timezone) => {
  return `
    ${timezone?.name?.replace(/\//g, ", ").replace(/_/g, " ").replace(/-/g, " ")}
    (${timezone?.code})
  `
}

/**
 * This function scroll to the target element referenced by the clicked anchor link.
 * @param {Event} e - The click event.
 */

export const scrollToTargetElement = (e) => {
  const href = e.currentTarget.getAttribute("href");
  const targetId = href.substring(1);
  const targetElement = document.getElementById(targetId);
  
  if (!!targetElement) {
    e.preventDefault();
    targetElement.scrollIntoView({
      behavior: "smooth",
    });

    window.history.pushState(null, "", `#${targetId}`);
  }
};
